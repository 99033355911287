/*
  This class wil be used to handle all enviroment
  variables like API TOkens or URLS
*/
export default class Enviroment {
  
  /*
    This function detects if the envoriment is production
    or development and returns the properly API_URL 
  */
  static getUrl(){
    /*read de node Env variable https://create-react-app.dev/docs/adding-custom-environment-variables/#expanding-environment-variables-in-env */
    if(process.env.REACT_APP_STAGE ==='production'){
      //Production mode
      return 'https://tingoapi.herokuapp.com/v1'
    }else{
      // local and test url
      //return 'http://localhost:3000/v1'
      return 'https://tingoapi.herokuapp.com/v1'
    }
  }
}